<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex">
          <feather-icon icon="CoffeeIcon" size="16"/>
          <h5 class="mb-0 mt-0 ml-50">Genel Bilgiler</h5>
        </div>

        <b-row class="mt-1 mb-1">

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="İsim" rules="required">
              <b-form-group label="* İsim" :state="getValidationState(validationContext)">
                <b-form-input trim placeholder="İsim" v-model="dataInfo.name"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Sistem İzin Tipi" rules="required">
              <b-form-group label="* Sistem İzin Tipi" label-for="isActive" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.systemLeaveTypeId" :options="systemLeaveTypeOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Ücretli" rules="required">
              <b-form-group label="* Ücretli" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.isPaid" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Varsayılan" rules="required">
              <b-form-group label="* Varsayılan" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.isDefault" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Durum" rules="required">
              <b-form-group label="* Durum" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Hesaplama Türü" rules="required">
              <b-form-group label="* Hesaplama Türü" description="Çalışanlar saatlik izin talep ettiğinde nasıl hesaplanmasını istiyorsunuz?" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.leaveCalculateTypeId" :clearable="false" :options="leaveCalculateTypeOptions" label="label" :reduce="val => val.value">
                  <template #option="{ description, label }">
                    <feather-icon icon="InfoIcon" size="16" v-b-tooltip.hover.bottom="`${description}`"/>
                    <span class="ml-50 align-middle"> {{ label }}</span>
                  </template>
                  <template #selected-option="{ avatar, label }">
                    <span class="ml-50 align-middle"> {{ label }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <div class="d-flex">
          <feather-icon icon="CoffeeIcon" size="16"/>
          <h5 class="mb-0 mt-0 ml-50">Limit Bilgileri</h5>
        </div>

        <b-row class="mt-1 mb-1">

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Limit" rules="required">
              <b-form-group label="* Limit" description="Çalışanların aldıkları izin süreleri nasıl olacak?" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.leaveLimitTypeId" :clearable="false" :options="leaveLimitTypeOptions" label="label" :reduce="val => val.value">
                  <template #option="{ description, label }">
                    <feather-icon icon="InfoIcon" size="16" v-b-tooltip.hover.bottom="`${description}`"/>
                    <span class="ml-50 align-middle"> {{ label }}</span>
                  </template>
                  <template #selected-option="{ avatar, label }">
                    <span class="ml-50 align-middle"> {{ label }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Limit Gün">
              <b-form-group label="Limit Gün" :state="getValidationState(validationContext)">
                <b-form-input trim placeholder="Limit Gün" v-model="dataInfo.limitDurationDay"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <div class="d-flex">
          <feather-icon icon="CoffeeIcon" size="16"/>
          <h5 class="mb-0 mt-0 ml-50">Hakediş Bilgileri</h5>
        </div>

        <b-row class="mt-1 mb-1">

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Hakediş Sıklığı">
              <b-form-group label="Hakediş Sıklığı" description="Çalışanlar hangi sıklıkla izin hakedecek?" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.entitlementFrequencyTypeId" :options="entitlementFrequencyTypeOptions" :reduce="val => val.value" :clearable="true"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Hakediş Tarihi">
              <b-form-group label="Hakediş Tarihi" description="Çalışanlar hangi tarih itibariyle hakedecek?" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.entitlementDateTypeId" :options="entitlementDateTypeOptions" :reduce="val => val.value" :clearable="true"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Eksi Bakiye Durumu">
              <b-form-group label="Eksi Bakiye Durumu" description="Çalışanların izin hakedişleri olmadığı durumda ne olacak?" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.minusBalanceTypeId" :options="minusBalanceTypeOptions" :reduce="val => val.value" :clearable="true"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Devretme Durumu">
              <b-form-group label="Devretme Durumu" description="Çalışanlar hakedişlerini kullanmadıklarında ne olur?" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.leaveTransferTypeId" :options="leaveTransferTypeOptions" :reduce="val => val.value" :clearable="true"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <div class="d-flex">
          <feather-icon icon="CoffeeIcon" size="16"/>
          <h5 class="mb-0 mt-0 ml-50">Talep Bilgileri</h5>
        </div>

        <b-row class="mt-1 mb-1">

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Talep En Az">
              <b-form-group label="Talep En Az" description="Çalışanlar tek seferde en az kaç gün talep edebilir?" :state="getValidationState(validationContext)">
                <b-form-input trim placeholder="Talep En Az" v-model="dataInfo.minimumDemandLeaveDay"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Talep En Çok">
              <b-form-group label="Talep En Çok" description="Çalışanlar tek seferde en fazla kaç gün talep edebilir?" :state="getValidationState(validationContext)">
                <b-form-input trim placeholder="Talep En Çok" v-model="dataInfo.maximumDemandLeaveDay"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>


        <div class="d-flex">
          <feather-icon icon="CoffeeIcon" size="16"/>
          <h5 class="mb-0 mt-0 ml-50">Diğer</h5>
        </div>

        <b-row class="mt-1 mb-1">

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Sıra No" rules="required">
              <b-form-group label="* Sıra No" description="Gösterim sırasını belirtmek için kullanılır." :state="getValidationState(validationContext)">
                <b-form-input trim placeholder="Sıra No" v-model="dataInfo.sortNumber"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Çalışılmayan Günler Dahil Edilsin">
              <b-form-group label="Çalışılmayan Günler Dahil Edilsin" description="İzin süresine çalışılmayan günler dahil edilsin mi?" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.nonWorkingDays" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Cinsiyet">
              <b-form-group label="Cinsiyet" description="Sadece ilgili cinsiyete sahip kullanıcılar tarafından seçilebilir." :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.genderId" :options="genderOptions" :reduce="val => val.value" :clearable="true"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Medeni Durum">
              <b-form-group label="Medeni Durum" description="Sadece ilgili medeni duruma sahip kullanıcılar tarafından seçilebilir." :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.maritalStatusId" :options="maritalStatusOptions" :reduce="val => val.value" :clearable="true"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Açıklama">
              <b-form-group label="Açıklama">
                <b-form-textarea v-model="dataInfo.description" rows="2" placeholder="Açıklama..."/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <div class="d-flex" v-if="dataInfo.systemLeaveTypeId === 'ANNUAL_LEAVE'">
          <feather-icon icon="CoffeeIcon" size="16"/>
          <h5 class="mb-0 mt-0 ml-50">Kıdem Bilgileri</h5>
        </div>

        <div class="mt-1" v-if="dataInfo.systemLeaveTypeId === 'ANNUAL_LEAVE'">
          <b-row class="border-bottom border-top p-1">
            <b-col cols="12" md="4" lg="4" class="pl-0">
              <b-button variant="primary" @click="newSeniorityModal">
                <feather-icon icon="PlusSquareIcon" size="16"/>
                <span class="align-middle" role="button"> Ekle</span>
              </b-button>
            </b-col>
          </b-row>

          <div class="pt-1">

            <div v-for="(seniority, index) in dataInfo.seniorityYears" :key="index" class="d-flex justify-content-between align-items-center border-bottom py-1" v-if="!seniority.isDeleted">
              <div class="d-flex justify-content-between align-items-center">
                <b-avatar size="32" class="mr-50" :text="(index+1).toString()" :variant="`light-primary`"/>
                <div class="user-page-info">
                  <h6 class="mb-0">
                    {{ seniority.startYear }} - {{ seniority.endYear }} Yıl Arasında
                  </h6>
                  <small class="text-muted">{{ seniority.leaveDay }} Gün</small>
                </div>
              </div>
              <div>
                <b-button variant="warning" class="btn-icon" @click="editSeniority(seniority)">
                  <feather-icon icon="EditIcon"/>
                  Düzenle
                </b-button>
                <b-button variant="danger" class="btn-icon ml-1" @click="removeSeniority(seniority)">
                  <feather-icon icon="XIcon"/>
                  Çıkar
                </b-button>
              </div>
            </div>

          </div>

        </div>

        <b-modal v-model="modalShow" title="Kıdem Bilgileri" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @show="resetModal" @hidden="resetModal" @ok="handleModal">
          <form ref="form" @submit.stop.prevent="submitModal">

            <b-form-group label="Başlangıç Yılı">
              <b-form-input trim placeholder="Başlangıç Yılı" v-model="seniorityInfo.startYear"/>
            </b-form-group>

            <b-form-group label="Bitiş Yılı (Dahil)">
              <b-form-input trim placeholder="Bitiş Yılı (Dahil)" v-model="seniorityInfo.endYear"/>
            </b-form-group>

            <b-form-group label="İzin Günü">
              <b-form-input trim placeholder="İzin Günü" v-model="seniorityInfo.leaveDay"/>
            </b-form-group>

          </form>
        </b-modal>

        <action-buttons :back-route="'definition-leavetype-list'"/>

      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {BAvatar, VBPopover, VBTooltip, BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTags, BFormTextarea, BModal, BRow, BTable} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {email, required} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/definition/leavetype/store"
import definitionModule from "@/views/system/definition/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import Vue from "vue"

export default {
  components: {
    BFormTags,
    BAvatar,
    BModal,
    BTable,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    AppCollapseItem,
    AppCollapse,
    ActionButtons,
    Overlay,
    vSelect,
    VBPopover,
    VBTooltip,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    }
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: 0,
      sortNumber: 0,
      name: '',
      systemLeaveTypeId: null,
      description: '',
      isPaid: true,
      leaveLimitTypeId: null,
      limitDurationDay: null,
      minusBalanceTypeId: null,
      entitlementFrequencyTypeId: null,
      entitlementDateTypeId: null,
      leaveTransferTypeId: null,
      minimumDemandLeaveDay: null,
      maximumDemandLeaveDay: null,
      leaveCalculateTypeId: null,
      nonWorkingDays: false,
      genderId: null,
      maritalStatusId: null,
      isDefault: false,
      isSystem: false,
      isActive: true,
      seniorityYears: []
    })

    const seniorityInfo = ref({
      id: null,
      startYear: null,
      endYear: null,
      leaveDay: null,
      isDeleted: false,
    })
    const seniorityEditInfo = ref(null)
    const modalShow = ref(false)

    busy.value = true
    const systemLeaveTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'SYSTEM_LEAVE_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        systemLeaveTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.systemLeaveTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const leaveLimitTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'LEAVE_LIMIT_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        leaveLimitTypeOptions.value.push({label: value.name, value: value.key, description: value.description ?? ''})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.leaveLimitTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const minusBalanceTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'LEAVE_MINUS_BALANCE_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        minusBalanceTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.minusBalanceTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const entitlementFrequencyTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'LEAVE_ENTITLEMENT_FREQUENCY_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        entitlementFrequencyTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.entitlementFrequencyTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const entitlementDateTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'LEAVE_ENTITLEMENT_DATE_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        entitlementDateTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.entitlementDateTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const leaveTransferTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'LEAVE_TRANSFER_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        leaveTransferTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.leaveTransferTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const leaveCalculateTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'LEAVE_CALCULATE_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        leaveCalculateTypeOptions.value.push({label: value.name, value: value.key, description: value.description ?? ''})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.leaveCalculateTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const maritalStatusOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'MARITAL_STATUS'}).then(response => {
      response.data.data.forEach((value, index) => {
        maritalStatusOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.maritalStatusId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    const genderOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'GENDER'}).then(response => {
      response.data.data.forEach((value, index) => {
        genderOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.genderId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'definition-leavetype-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const newSeniorityModal = () => {
      seniorityInfo.value = {
        id: 0,
        startYear: null,
        endYear: null,
        leaveDay: null,
        isDeleted: false,
      }

      modalShow.value = true
    }

    const resetModal = () => {
    }

    const submitModal = () => {
      let isAppendItem = true
      dataInfo.value.seniorityYears.forEach((value, index) => {
        if (value === seniorityEditInfo.value) {
          isAppendItem = false
          value.id = parseInt(seniorityInfo.value.id)
          value.startYear = parseInt(seniorityInfo.value.startYear)
          value.endYear = parseInt(seniorityInfo.value.endYear)
          value.leaveDay = parseInt(seniorityInfo.value.leaveDay)
          value.isDeleted = seniorityInfo.value.isDeleted
        }
      });

      if (isAppendItem) {
        dataInfo.value.seniorityYears.push({
          id: parseInt(seniorityInfo.value.id),
          startYear: parseInt(seniorityInfo.value.startYear),
          endYear: parseInt(seniorityInfo.value.endYear),
          leaveDay: parseInt(seniorityInfo.value.leaveDay),
          isDeleted: seniorityInfo.value.isDeleted,
        })
      }

      seniorityInfo.value = {
        id: 0,
        startYear: null,
        endYear: null,
        leaveDay: null,
        isDeleted: false,
      }
      modalShow.value = false
    }

    const handleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      submitModal()
    }

    const removeSeniority = (seniorityData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.seniorityYears.forEach((value, index) => {
            if (value === seniorityData) {
              value.isDeleted = true
            }
          });
        }
      })
    }

    const editSeniority = (seniorityData) => {
      seniorityEditInfo.value = seniorityData

      seniorityInfo.value = {
        id: seniorityData.id,
        startYear: seniorityData.startYear,
        endYear: seniorityData.endYear,
        leaveDay: seniorityData.leaveDay,
        isDeleted: seniorityData.isDeleted,
      };

      modalShow.value = true
    }

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'definition-leavetype-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      busy,
      dataInfo,
      refFormObserver,
      systemLeaveTypeOptions,
      leaveLimitTypeOptions,
      minusBalanceTypeOptions,
      entitlementFrequencyTypeOptions,
      entitlementDateTypeOptions,
      leaveTransferTypeOptions,
      modalShow,
      seniorityInfo,
      leaveCalculateTypeOptions,
      genderOptions,
      maritalStatusOptions,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      newSeniorityModal,
      resetModal,
      submitModal,
      handleModal,
      removeSeniority,
      editSeniority,
      avatarText,
      getApiFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
